<template>
  <b-overlay :show="loading">
    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
      <ValidationProvider name="Grade Title (En)" vid="title" rules="required">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="title"
            slot-scope="{ valid, errors }"
        >
          <template v-slot:label>
            {{ $t('elearning_config.grade_title') }} {{ $t('globalTrans.en') }} <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="title"
            v-model="formData.title"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <div class="invalid-feedback">
          {{ errors[0] }}
          </div>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider name="Grade Title (Bn)" vid="title_bn" rules="required">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="title_bn"
            slot-scope="{ valid, errors }"
        >
          <template v-slot:label>
            {{ $t('elearning_config.grade_title') }} {{ $t('globalTrans.bn') }} <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="title_bn"
            v-model="formData.title_bn"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <div class="invalid-feedback">
          {{ errors[0] }}
          </div>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider name="Title Mark" vid="average_number" :rules="'required|max_value:' + maxValue">
        <b-form-group
            class="row"
            label-cols-sm="4"
            label-for="average_number"
            slot-scope="{ valid, errors }"
        >
          <template v-slot:label>
            {{ $t('elearning_config.title_mark') }} <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            id="average_number"
            v-model="formData.average_number"
            :state="errors[0] ? false : (valid ? true : null)"
          ></b-form-input>
          <div class="invalid-feedback">
          {{ errors[0] }}
          </div>
        </b-form-group>
      </ValidationProvider>
      <div class="row">
      <div class="col-sm-3"></div>
      <div class="col text-right">
          <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
          <b-button variant="danger" class="btn-sm" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
      </div>
      </div>
    </b-form>
    </ValidationObserver>
  </b-overlay>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { evaluationGradeStore, evaluationGradeUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getEditingData()
      this.formData = tmp
    }
  },
  mounted () {
  },
  data () {
    return {
      loading: false,
      totalPost: 0,
      maxValue: 5,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        title: '',
        title_bn: '',
        average_number: ''
      }
    }
  },
  computed: {
  },
  methods: {
    getEditingData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async register () {
      this.loading = true
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadingState = { loading: false, listReload: false }

      if (this.formData.id) {
        result = await RestApi.putData(trainingElearningServiceBaseUrl, `${evaluationGradeUpdate}/${this.id}`, this.formData)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, evaluationGradeStore, this.formData)
      }

      loadingState.listReload = true
      this.$store.dispatch('mutateCommonProperties', loadingState)

      if (result.success) {
        this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
